
var cache = {}
function loadScript(link) {
    if (cache[link]) return Promise.resolve()
    return new Promise((resolve, reject) => {
        function success() {
            cache[link] = true
            resolve()
        }
        function fail() {
            reject(link)
        }
        const script = document.createElement('script');
        script.src = link;
        script.onload = success;
        script.onerror = fail;
        document.getElementsByTagName('head')[0].appendChild(script);
    })
}
function asyncSeries(funs) {
    return funs.reduce((promise, fun) => promise.then(fun), Promise.resolve());
}
export function loadStaticSource(links) {
    return new Promise((resolve, reject) => {
        let p = asyncSeries(links.map((link) => () => loadScript(link)))
        p.then(resolve)
            .catch(reject);
    })
}

/**
 *
 * @parmas string | [] JS URL 例：['https://storage.360buyimg.com/cdn-common/zepto.min.js'] or 'https://storage.360buyimg.com/cdn-common/zepto.min.js'
 * @type {{}}
 * demo
 loadjs(["https://storage.360buyimg.com/cdn-common/zepto.min.js"]).then(
 () => {
		console.log("成功")
	},
 () => {
		console.log("加载失败")
	}
 )
 */
export function loadjs(list, $el = "body",attr) {
    const cache = {}
    const head = document[$el] || document.getElementsByTagName("head")[0] || document.documentElement
    function exec(url) {
        if (!url) return
        const options = {
            url: url,
            crossorigin: "anonymous",
            charset: "utf-8",
            type: "text/javascript",
            ext:{...attr}
        }
        console.log(options,attr, "loadjs")
        const cacheId = options.url
        const cacheEntry = cache[cacheId]

        if (cacheEntry) {
            return cacheEntry
        } else {
            // eslint-disable-next-line no-use-before-define
            const el = getScriptByUrl(options.url)
            if (el) {
                const promise = Promise.resolve(el)
                if (cacheId) {
                    cache[cacheId] = promise
                }
                return promise
            }
        }
        if (!options) {
            throw new Error("load-js: must provide a url or text to load")
        }

        // eslint-disable-next-line no-use-before-define
        const pending = (options ? loadScript : runScript)(head, createScript(options))
        if (cacheId !== false) {
            cache[cacheId] = pending
        }
        return pending
    }

    // eslint-disable-next-line no-shadow
    function runScript(head, script) {
        head.appendChild(script)
        return Promise.resolve(script)
    }

    // eslint-disable-next-line no-shadow
    function loadScript(head, script) {
        return new Promise(function (resolve, reject) {
            // Handle Script loading
            let done = false

            // Attach handlers for all browsers.
            //
            // References:
            // http://stackoverflow.com/questions/4845762/onload-handler-for-script-tag-in-internet-explorer
            // http://stevesouders.com/efws/script-onload.php
            // https://www.html5rocks.com/en/tutorials/speed/script-loading/
            // eslint-disable-next-line no-multi-assign
            script.onload = script.onreadystatechange = function () {
                if (!done && (!script.readyState || script.readyState === "loaded" || script.readyState === "complete")) {
                    done = true

                    // Handle memory leak in IE
                    // eslint-disable-next-line no-multi-assign
                    script.onload = script.onreadystatechange = null
                    resolve(script)
                }
            }

            script.onerror = resolve

            head.appendChild(script)
        })
    }

    function createScript(options) {
        const script = document.createElement("script")
        script.charset = options.charset || "utf-8"
        script.type = options.type || "text/javascript"
        // script.defer = "defer"
        script.crossOrigin = options.crossorigin || "anonymous"
        options.ext&&Object.keys(options.ext).map(item=>{
            script.setAttribute(item,options.ext[item])
        })

        if (options.url) {
            script.src = options.url
        }

        return script
    }

    function getScriptByUrl(url) {
        const script = url && document.querySelector("script[src='" + url + "']")
        if (script && script.loadJS !== "watermark") {
            console.warn("load-js: duplicate script with url:", url)
            return script
        }
    }
    return list instanceof Array ? Promise.all(list.map(exec)) : exec(list)
}

export const insertCDN = (links,el,attr) => {
    loadjs(links,el,attr)
      .then((res) => {
      })
      .catch((err) => {
          console.log(err, "insertCDN-error")
      })
}

