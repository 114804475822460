// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import {setEnv, rationPage} from './api/index'
import {loadStaticSource, insertCDN} from './utils/loadPlugs'
import { retainFn, retainClose } from './utils/retain'
import {goBackLastPage, webviewRetainFn} from './utils/webviewRation.js'
import {isApp} from "@jd/fe-common"
import report from './utils/report.js'
const __rationidArr__ : number[]=[] // 所有消息ID存储，暂时留存
interface opts {
    show?: boolean,// iframe 是否展示，todo:暂时未使用属性
    frameUrl?: string, // 展示组件的地址，todo：暂时未使用
    furl?: string,
    channelId: number | string, // 策略ID
    babelActivityId?: number | string, // 通天塔活动ID
    closeBtn?: boolean, // 干预层自带关闭按钮，如果组件层用自己关闭按钮及控制位置配置false
    env?: string, // 手动控制接口环境，dev-预发。
    ftype?: string,//run-自执行业务方无感知；fn-业务方自己触发并定制，自己实例化，对象属性自己可调遣
    getMsg?: (o) => {}, // 接收消息
    setMsg?: object | string,// 推送消息内容
    dialogData?: object,// 支持SDK接入方传入数据
}
// SDK 埋点事件
const reporObj = {
    sdkInit: 'Babel_dev_expo_other_WLSDK_Init', // sdk加载
    sdkTransData: 'Babel_dev_expo_other_WLSDK_TransData', // 透传数据
    sdkLoadData: 'Babel_dev_expo_other_WLSDK_Request', // sdk加载数据或者请求数据
    sdkDataSuccess: 'Babel_dev_expo_other_WLSDK_RequestSuccess', // sdk数据加载成功
    sdkDataError: 'Babel_dev_expo_other_WLSDK_RequestFail', // sdk数据加载失败
    sdkDataSend: 'Babel_dev_expo_other_WLSDK_DataSend', // sdk数据发放
    sdkRationRegist: 'Babel_dev_expo_other_WLSDK_RegistEvent', //注册挽留事件
    sdkRunByUser: 'Babel_dev_expo_other_WLSDK_RunByUser', //接入方主动触发弹窗展示
    sdkRationClick: 'Babel_dev_other_WLSDK_BackClick', // 挽留事件触发
    sdkDialogClose: 'Babel_dev_other_WLSDK_Close', // 弹窗关闭
}
class rationPlug{
    jsLoad: boolean
    public opts: opts
    private data : any
    private babelObj : Object
    private isFirst: boolean
    private rationid: number
    private isRetain: boolean
    constructor(opt:opts) {
        this.opts = opt
        this.opts.closeBtn=this.opts.closeBtn==undefined || this.opts.closeBtn==null || this.opts.closeBtn==true||this.opts.closeBtn !=false
        this.jsLoad = false
        this.data = {}
        this.babelObj=window['__react_data__'] || {} // 通天塔信息
        this.isFirst = true
        this.isRetain = false
        this.rationid=new Date().getTime()
        this._load() // js前置依赖加载
        // 是否变更调用环境
        setEnv(opt.env)
    }
    // 展示弹窗 TODO: 20240312发现使用此方法ihub组件会多次render，原因未知
    setDialogShowRationSdkFunc = () => {
        const that=this
        // 接入方调用该方法的时机：一进入就调用；设置iframe-show直接展示，不再display:none
        this.opts.show = true;
        // 接入方触发弹窗展示埋点上报
        report.clickPing({
            eventId: reporObj.sdkRunByUser,
            jsonParam: JSON.stringify({
                channelId: this.opts.channelId,
                lpid: this.babelObj['activityData']?.activityId ||"",
                source_url: window.location.href
            })
        })
        // 接入方调用该方法的时机，不是一进入就调用，iframe初始状态是display:none，使用show()展示iframe
        console.log("手动触发show")
        that.includeShow(true)
        // 手动触发弹框组件不触发曝光问题，触发在前 加载在后导致组件内拿不到消息，待组件给ready后触发曝光
        window.addEventListener('message',function(e) {
            const messageid = that.rationid
            // 手动触发待组件加载完通知SDK，SDK发送曝光展示消息通知来进行曝光埋点上报
            if (e.data[messageid]?.ready) {
                console.log("手动触发接受消息", e)
                // $(`#frame-yhzz${that.rationid}`).show()
               // 通知加载完后触发展示逻辑
               // that.includeShow(true)
               that._setMessage('expo',{showMessage: true})
            }
        })
    }
    _load() {
        // 不加载https://wl.jd.com/unify.min.js方法，能用就上报不能用就不上报
        if(!(window as any).$  || !(window as any).zepto){
            loadStaticSource(['https://storage.360buyimg.com/cdn-common/zepto.min.js']).then(() => {
                this.jsLoad=true
                this.init()
            }, () => {
                this.jsLoad=false
                console.log("前置zepto加载失败")
            })
        }else{ // 页面已载入
            this.jsLoad=true
            this.init()
        }
        try{
            (window as any).__sgm__&&(window as any).__sgm__.custom({type:3,code:"SDK_LOAD",msg: "_load"}, "9HwAEg@9HwAEg@4b26MLtMdwWxLUvw")
        }catch (err){
            console.log(err, "sgm_err")
        }

    }
    async init(){
        // 判断是否传递了数据，如果有数据，直接处理数据，如果没有数据就请求数据
        const {dialogData, channelId, babelActivityId, frameUrl} = this.opts
        if(!channelId && !dialogData){
            return;
        }
        __rationidArr__.push(this.rationid)
        $("body").append(`<script>document.domain="jd.com"</script>`)
        // 监听消息务必一开始就进行
        this._getMessage()
        console.log("retain init",this.opts)
        // sdk调用埋点
        report.clickPing({
            eventId: reporObj.sdkInit,
            jsonParam: JSON.stringify({
                channelId: this.opts.channelId,
                lpid: this.babelObj['activityData']?.activityId ||"",
                source_url: window.location.href
            })
        })
        // 如果是有返回弹窗数据，直接处理
        const that:any=this
        if(dialogData){
             // 数据透传的埋点
             report.clickPing({
                eventId: reporObj.sdkTransData,
                jsonParam: JSON.stringify({
                    channelId: channelId,
                    lpid: this.babelObj['activityData']?.activityId ||"",
                    source_url: window.location.href,
                    touchstone_expids: that.opts.dialogData?.touchstoneExpIds ||"",
                })
            })
            this.data = this.opts.dialogData
            this._handleData()
        }else{
            // 接口请求的埋点
            report.clickPing({
                eventId: reporObj.sdkLoadData,
                jsonParam: JSON.stringify({
                    channelId: channelId,
                    lpid: this.babelObj['activityData']?.activityId ||"",
                    source_url: window.location.href
                })
            })
            // 如果有babelActivityId，说明是通天塔组件，请求数据 11147挽留  111003呼脸弹窗
            rationPage({
                channelId: channelId || "11147",
                ext:{
                    babelActivityId,
                    babelPageUrl:window.location.href || "",// 当前业务方URL,
                }
            }).then(res => {
                /**
                 * mock 数据
                 */
                /**
                 * 第一版9.9挽留弹框使用老的数据协议
                 * materialDisplayList[0].materialType 1:商品弹框 3：广告弹框
                 * isData99 老协议componentDisplayList为null 并且materialType里存在如上两种类型其中一种即为9.9弹框
                 */
                const isData99=!res?.data?.componentDisplayList?.lenght && res?.data?.materialDisplayList?.length && (res?.data?.materialDisplayList[0]?.materialType==1||res?.data?.materialDisplayList[0]?.materialType==3)
                console.log("老协议",isData99)
                if(res?.data && !isData99){
                    // 新协议接口形式
                    this.data = res.data
                    this._handleData()
                    // 请求成功
                    report.clickPing({
                        eventId: reporObj.sdkDataSuccess,
                        jsonParam: JSON.stringify({
                            channelId,
                            lpid: this.babelObj['activityData']?.activityId ||"",
                            source_url: window.location.href,
                            touchstone_expids: res?.data?.touchstoneExpIds ||"",
                        })
                    })
                }else if(isData99){
                    // 老协议-9.9包邮弹框
                    this.data = res.data
                    this._handleData99()
                    // 请求成功
                    report.clickPing({
                        eventId: reporObj.sdkDataSuccess,
                        jsonParam: JSON.stringify({
                            channelId,
                            lpid: this.babelObj['activityData']?.activityId ||"",
                            source_url: window.location.href,
                            touchstone_expids: res?.data?.touchstoneExpIds ||"",
                        })
                    })
                }else{
                    // 请求失败
                    report.clickPing({
                        eventId: reporObj.sdkDataError,
                        jsonParam: JSON.stringify({
                            channelId,
                            lpid: this.babelObj['activityData']?.activityId ||"",
                            source_url: window.location.href
                        })
                    })
                }
            }).catch(err => {
                // 请求失败
                report.clickPing({
                    eventId: reporObj.sdkDataError,
                    jsonParam: JSON.stringify({
                        channelId,
                        lpid: this.babelObj['activityData']?.activityId ||"",
                        source_url: window.location.href
                    })
                })
            })
        }

    }

    /**
     * 9.9数据
     */
    _handleData99(){
        const materialDisplayList=this.data?.materialDisplayList
          //页面工厂链接，用于加载通天塔的共建楼层
        const pagesFactoryUrl = this.opts.env === 'dev' ?
          'https://pages-factory-test1.local-pf.jd.com/' :
          'https://pages-factory-pro.pf.jd.com/'
        const componentId=this.opts.env==='dev'? 89824583:89781128
        // 拼接moduleId,加载对应组件
        const iframeUrl = `${pagesFactoryUrl}?moduleId=${componentId}&channelId=${this.opts.channelId}&messageid=${this.rationid}`
        // 如果show为展示，直接渲染ifram为展示状态
        if(this.opts.show){
            this.include({iframeUrl})
            this.includeShow(true)
        }else {
            this.include({iframeUrl})
        }
        // 如果ftype-使用客制化，后续关于挽留和进入弹窗代码不再执行
        if(this.opts.ftype === "custom"){
            return;
        }else{
            console.log("_handleData99")
            // 触发方式-老协议只有挽留方式触发
            this.isRetain = true
            // 注册挽留事件
            this.initRation()
        }
    }
    private _handleData(){
        // 看一下是呼脸还是挽留，如果是挽留注册挽留事件
        // TODO: 20240129发现万花筒链接的代码有问题，只是废弃此方式，不能从componentDisplayList[0].materialInfo[0].materialDetail.【appUrl/h5Url】这个位置获取URL，这是广告点击后的落地页
        const componentDisplayList = this.data?.componentDisplayList
        if(componentDisplayList?.length){
            // 万花筒链接，目前放至在componentDisplayList[0].materialInfo[0].materialDetail.【appUrl/h5Url】
            // const whtH5Url = componentDisplayList[0]?.materialInfo?.[0]?.materialDetail?.h5Url
            // const whtH5Url = this.opts.frameUrl || ""
            // 组件在通天塔活动页面对应的moduleId
            let id = componentDisplayList[0]?.id

            // 万花筒链接和组件ID都没有的时候直接return
            if(!id){
                return
            }
            //页面工厂链接，用于加载通天塔的共建楼层
            const pagesFactoryUrl = this.opts.env === 'dev' ?
              'https://pages-factory-test1.local-pf.jd.com/' :
              'https://pages-factory-pro.pf.jd.com/'
            // 拼接moduleId,加载对应组件
            const iframeUrlWithModuleId = `${this.opts.furl || pagesFactoryUrl}?moduleId=${id}&channelId=${this.opts.channelId}&messageid=${this.rationid}`

            // 如果有配置万花筒链接，展示万花筒，没有配置万花筒链接，使用moduleId加载通天塔组件
            /**
             * whtH5Url 待正则判断url是否有参入增加SDK的渠道及消息ID等参数
             * 20240129 whtH5Url为广告使用的跳转链接，判断逻辑不对需要重新跳转，万花筒使用需要重新评估，暂时注释
             * const iframeUrl = whtH5Url ?
             whtH5Url.includes('?')? `${whtH5Url}&moduleId=${id}&channelId=${this.opts.channelId}&messageid=${this.rationid}`:`${whtH5Url}?moduleId=${id}&channelId=${this.opts.channelId}&messageid=${this.rationid}`
             : iframeUrlWithModuleId
             */
            const iframeUrl = iframeUrlWithModuleId
            console.log(iframeUrlWithModuleId, iframeUrl, "frameUrl")
            // 如果show为展示，直接渲染ifram为展示状态
            if(this.opts.show){
                this.include({iframeUrl})
                this.includeShow(true)
            }else {
                this.include({iframeUrl})
                // $("body").append(`<iframe id='frame-yhzz${this.rationid}' style='width:100vw;height: 100vh;position: fixed;top:0;left:0; display: none;' frameborder='0' src='${this.opts.frameUrl ? this.opts.frameUrl : iframeUrl}'></iframe>`)
            }

            // 如果ftype-使用客制化，后续关于挽留和进入弹窗代码不再执行
            if(this.opts.ftype === "custom"){
                return;
            }
            // 触发方式，back-点击返回的时候，enter-进入展示
            const activeMode = componentDisplayList[0]?.configInfo?.activeMode
            console.log(activeMode,activeMode, 'activeMode')
            if (activeMode === 'back') {
                this.isRetain = true
                report.clickPing({
                    eventId: reporObj.sdkRationRegist,
                    jsonParam: JSON.stringify({
                        channelId: this.opts.channelId,
                        lpid: this.babelObj['activityData']?.activityId ||"",
                        source_url: window.location.href
                    })
                })
                // 注册挽留事件
                this.initRation()
                // webviewRetainFn({
                //     success: () => {
                //         // 获取挽留弹窗的数据 只有成功的时候，获取挽留弹窗的数据
                //         // 挽留事件点击
                //         report.clickPing({
                //             eventId: reporObj.sdkRationClick,
                //             jsonParam: JSON.stringify({
                //                 channelId: this.opts.channelId,
                //                 lpid: this.babelObj['activityId'] ||"",
                //                 source_url: window.location.href
                //             })
                //         })
                //         if (this.isFirst) {
                //             // 展示iframe,不需要自组件通知展示
                //             this.includeShow(true)
                //             this.isFirst=false
                //             // $(`#frame-yhzz${this.rationid}`).show()
                //         } else {
                //             goBackLastPage()
                //         }
                //     },
                //     fail: () => {
                //         // 注册监听失败
                //         // goBackLastPage()
                //     },
                // })
            } else if (activeMode === 'enter') {
                //   进入展示，展示iframe,不需要自组件通知展示
                this.isRetain=false
            }
        }
    }
    // TODO: 挽留APP和非APP全方法暂时不使用，后续有需要再启用
    private initRation(){
        console.log("initRation")
        if(isApp('jd')){ // webview 挽留拦截
            webviewRetainFn({
                success: () => {
                    console.log("webview 监听成功")
                    // 获取挽留弹窗的数据 只有成功的时候，获取挽留弹窗的数据
                    // 挽留事件点击
                    report.clickPing({
                        eventId: reporObj.sdkRationClick,
                        jsonParam: JSON.stringify({
                            channelId: this.opts.channelId,
                            lpid: this.babelObj['activityData']?.activityId ||"",
                            source_url: window.location.href
                        })
                    })
                    if (this.isFirst) {
                        // 展示iframe,不需要自组件通知展示
                        this.includeShow(true)
                        this.isFirst=false
                        // $(`#frame-yhzz${this.rationid}`).show()
                    } else {
                        goBackLastPage()
                    }
                },
                fail: () => {
                    console.log("webview 监听失败")
                    // 注册监听失败
                    // goBackLastPage()
                },
            })
        }else{
            retainFn(()=>{
                // H5监听挽留触发
                console.log('H5拦截成功')
                report.clickPing({
                    eventId: reporObj.sdkRationClick,
                    jsonParam: JSON.stringify({
                        channelId: this.opts.channelId,
                        lpid: this.babelObj['activityData']?.activityId ||"",
                        source_url: window.location.href
                    })
                })
                // 展示iframe,不需要自组件通知展示
                this.includeShow(true)
                // $(`#frame-yhzz${this.rationid}`).show()
            })
        }
    }
    // 插入节点
    private include(opt){
        const that=this;
        console.log(this.opts,this.opts.closeBtn, "closeBtn")
        $("body").append(`${this.opts.closeBtn && `<img id='frame-yhzzimg${this.rationid}' class='frame_yhzzimg_closebtn' style='position: fixed;top:140px; right:20px; z-index:100001; width:8vw;height:8vw;display:none' src='https://img10.360buyimg.com/imagetools/jfs/t1/71171/19/24933/1223/641a65e9Fe8ecb2e5/4b79c8392a790b5c.png' alt='关闭' width='20px' height='20px' />`}<iframe id='frame-yhzz${this.rationid}' class='frame_yhzzimg_iframe' style='width:100vw;height: 100vh;position: fixed;top:0;left:0;z-index:100000;display:none' frameborder='0' src='${this.opts.frameUrl ? this.opts.frameUrl : opt.iframeUrl}'></iframe>`)
        $(`#frame-yhzzimg${this.rationid}`).click(()=>this.includeShow())
        /**
         * 监听iframe节点变化，当有class='tttfloor'时说明已加在完成
         */
        // this.observe()
    }
    // iframe 节点监听-不灵敏 有问题
    observe(){
        const that=this
        setTimeout(()=>{
        const observer = new MutationObserver(function (mutationsList, ob) {
            console.log("MutationObserver")
            // mutationsList参数是个MutationRecord对象数组，描述了每个发生的变化
            mutationsList.forEach(function (mutation) {
                var target = mutation.target;
                if ($(target.firstChild).hasClass('tttfloor')) {
                    console.log($(target.firstChild).hasClass('tttfloor'),"message23")
                    // 通知组件数据
                    that._setMessage("data")
                    if(!that.isRetain){ // 进入弹框通知组件展示
                        that.includeShow(true)
                    }
                    // 停止观察
                    observer.disconnect()
                    return false
                }
            });
        });
        // 开始观察iframe元素变化 偶现跨域问题
        /**
         * 只有延迟监听在真实环境才生效，不能太长目前100s最佳，失败率不高
         */

            try{
                // @ts-ignore
                observer.observe(document.getElementById(`frame-yhzz${this.rationid}`).contentWindow.document, {
                    childList: true, // 子节点的变动
                    subtree: true, // 表示是否将观察器应用于该节点的所有后代节点
                    attributes: true, // 属性变动
                    characterData: true, // 节点内容或节点文本的变动
                    attributeOldValue: true, // 表示观察 attributes 变动时，是否需要记录变动前的属性值
                    characterDataOldValue: true, // 表示观察 characterData 变动时，是否需要记录变动前的值
                    attributeFilter: ["style","class"] // 数组，表示需要观察的特定属性 （比如： ["class", "src"]）
                });
            }catch (err){console.log(err,"23")}
        },300)


    }
    // 节点显示/隐藏
    private includeShow(flag=false){
        console.log("message0oo", flag)
        if(flag){
            //给组件发弹窗数据
            this._setMessage('expo',{showMessage: true})
            $(`#frame-yhzzimg${this.rationid},#frame-yhzz${this.rationid}`).show()
        }else{
            // this._setMessage({showMessage: false},true)
            $(`#frame-yhzzimg${this.rationid}`).hide()
            $(`#frame-yhzz${this.rationid}`).remove()
        }
    }
    // 推送给iframe内的消息
    private _setMessage(type: string, json={}){
        const defaultData= this.data
        const channelId = this.opts.channelId
        const data={}
        data[this.rationid]={messageType:type,data:{...defaultData}, config:{...json}}
        // data[this.rationid]={...defaultData, ...json}
        setTimeout(()=>{
            // iframe传递数据
            report.clickPing({
                eventId: reporObj.sdkDataSend,
                jsonParam: JSON.stringify({
                    channelId,
                    lpid: this.babelObj['activityData']?.activityId ||"",
                    source_url: window.location.href,
                    touchstone_expids: defaultData?.touchstoneExpIds ||"",
                })
            })
            console.log(data, "setmessage")
            // @ts-ignore
            document.getElementById(`frame-yhzz${this.rationid}`).contentWindow.postMessage({setRationValue:{...defaultData, ...json}}, "*");
            // @ts-ignore
            document.getElementById(`frame-yhzz${this.rationid}`).contentWindow.postMessage(data, "*");
        },300)

    }
    // 接收来自iframe的消息
    private _getMessage(){
        const that= this
        const messageid=this.rationid
        window.addEventListener('message',function(e){
            console.log("get_message", e.data[messageid])
            //将消息回传给接入方
            e.data[messageid] && that.opts.getMsg && that.opts.getMsg(e.data[messageid])
            // 如果组件有发准备好的消息停止监听iframe，暂时废弃 TODO：后续考虑如何与监听兼容
            if (e.data[messageid]?.ready) {
                console.log("ready",e.data[messageid],that.isRetain)
                // 手动触发弹框组件不触发曝光问题，触发在前 加载在后导致组件内拿不到消息，待组件给ready后触发曝光
                // that.opts.ftype === "custom" && that._setMessage("expo",{showMessage: true})
                if(!that.isRetain){
                    console.log("ready2", that.opts.ftype)
                    that._setMessage("data")
                    that.opts.ftype !== "custom" && that.includeShow(true)
                    // that._setMessage("expo",{showMessage: true})
                }else{
                    that._setMessage("data")
                }
            }
            // 如果传入强制退出，关闭当前的webview
            if (e.data[messageid]?.exit) {
                isApp('jd')?goBackLastPage():retainClose()
            }
            // 触发关闭弹框操作，直接移除iframe节点
            if (e?.data[messageid]?.show === false) {
                console.log('closeIframe', e?.data[messageid])
                // iframe关闭
                report.clickPing({
                    eventId: reporObj.sdkDialogClose,
                    jsonParam: JSON.stringify({
                        channelId: that.opts.channelId,
                        lpid: that.babelObj['activityData']?.activityId ||"",
                        source_url: window.location.href
                    })
                })
                setTimeout(()=>that.includeShow(),300)
            }
        },false)
    }
}
;(()=>{
    console.log('load rationsdk')
    const scripts = document.getElementsByTagName("script");
    try{
        !(window as any).__sgm__&&insertCDN("https://sgm-static.jd.com/sgm-web-3.1.1.js","head",{name: "ration_sdk",sid:"ffff24e998a94c48bedee095f5c96398",pid:"9HwAEg@4b26MLtMdwWxLUvw"})
    }catch (err){}
    for (var i=0; i< scripts.length;i++) {
        var script = scripts[i];
        const initParams = {
            env: script.getAttribute("env") || "",
            ftype: script.getAttribute("ftype") || "run",//run-自执行业务方无感知,fn-业务方自己触发并定制
            channelId: script.getAttribute("channelId") || "",
            furl: script.getAttribute("furl") || "",
            closeBtn: JSON.parse(<string>script.getAttribute("closebtn"))===null || JSON.parse(<string>script.getAttribute("closebtn"))===true,
            babelActivityId: script.getAttribute('actId') || '',
            frameUrl: script.getAttribute("frameUrl") || ""
        }
        script?.getAttribute("ftype") == "run" && script?.getAttribute("channelId") && new rationPlug(initParams); // 自执行业务方无感知
        //传入ftype：custom或者fn，用户自己实例化，目前fn仅有天科优惠券楼层在用，参数值：fn不对外提供
        (script?.getAttribute("ftype") == "custom" ||script?.getAttribute("ftype") == "fn" )
        && ((window as any).rationPlug = rationPlug) // 业务方自己触发并定制
    }
})()
// CDN引入使用方式
export default rationPlug;