/**
 * 埋点文档：
 * @see {@link http://docs.stream.jd.com/02_SDK%20%E6%96%87%E6%A1%A3/06_M%E7%AB%AF%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C/}
 *
 * pv上报：
 * @param pageId: 必传，埋点定义的pageId
 * @param page_param: 选填
 *
 * 点击埋点上报 clickPing：
 * @param obj: 必传，对象格式传入对应的参数
 * 如果需要点击跳转，可在调用clickPing方法后，使用 setTimeout 方法进行跳转：
 *
 * clickPing({eventId: 'vip'})
 * setTimeout(()=>{
 *  location.href = '//www.jd.com'
 * }, 200)
 *
 */

// pv上报

// pv上报
// export const pv = (pageId, pageParam = null) => {
// 	try {
// 		const opv = new window.MPing.inputs.PV(pageId)
// 		opv.page_param = pageParam
// 		const mping = new window.MPing()
// 		mping.send(opv)
// 	} catch (e) {
// 		console.log("pv上报失败")
// 	}
// }

// // 点击埋点上报
// export const clickPing = (eventId, obj) => {
// 	try {
// 		const clickP = new window.MPing.inputs.Click(eventId)
// 		clickP.event_param = obj.eventParam || ""
// 		clickP.json_param = obj.jsonParam || ""
// 		clickP.event_level = obj.eventLevel || ""
// 		clickP.updateEventSeries()
// 		const mping = new window.MPing()
// 		mping.send(clickP)
// 	} catch (e) {
// 		console.log(e)
// 		console.log("点击埋点上报失败")
// 	}
// }

export default {
    pv(pageId, pageParam = null, eventParam = null) {
      try {
        const pv = new window.MPing.inputs.PV(pageId);
        pv.page_param = pageParam || '';
        pv.event_param = eventParam || '';
        const mping = new window.MPing();
        mping.send(pv);
      } catch (e) {
        console.log('pv上报失败');
      }
    },
  
    // 点击埋点上报
    clickPing(obj) {
      try {
        const clickP = new window.MPing.inputs.Click(obj.eventId);
        clickP.event_param = obj.eventParam || '';
        clickP.json_param = obj.jsonParam || '';
        clickP.event_level = obj.eventLevel || '';
        clickP.updateEventSeries();
        const mping = new window.MPing();
        mping.send(clickP);
      } catch (e) {
        console.log(e);
        console.log('点击埋点上报失败');
      }
    },
  };
  
  /*
   * productItem 商品信息
   * 取值：1：点击，2：曝光
   * */
  const getParamsFromProduct = (productItem, eventType = 1) => {
    let productItems = [];
    if (productItem && !Array.isArray(productItem)) {
      productItems = [productItem];
    } else {
      return null;
    }
    const params = productItems.reduce((acc, item) => {
      if (item.recomInfo) {
        acc.push({
          ubb_feed_v2:
            Number(eventType) === 1
              ? {
                  event_type: Number(eventType),
                  recom_info: item.recomInfo,
                }
              : [
                  {
                    event_type: Number(eventType),
                    recom_info: item.recomInfo,
                  },
                ],
        });
      }
      return acc;
    }, []);
    if (params && params.length > 1) {
      // 返回数组
      return params;
    }
    // 返回该商品新埋点
    return params && params[0];
  };
  /* 新埋点参数：为了组装新的埋点参数
   * @params:  item 资源信息, type 1 product 2 ...,  reportJson: 楼层埋点通用参数 , eventType 1:点击；2:曝光
   * @return ubb_comp： opType  detail 字段需要上报时获取 https://cf.jd.com/pages/viewpage.action?pageId=528783149
   * @return ubb_feed_v2：https://cf.jd.com/pages/viewpage.action?pageId=531729076
   * */
  export const getReportJson = (item, type = 1, reportJson, eventType = 1) => {
    const newReportJson = {...reportJson};
    // 埋点的类型1:点击；2:曝光；不能为空
    newReportJson.ubb_comp && (newReportJson.ubb_comp.opType = Number(eventType));
    // 埋点资源详情加密字段
    newReportJson.ubb_comp &&
      (newReportJson.ubb_comp.detail = (item && item.resourceReportInfo) || '');
    let extParams;
    if (type === 1) {
      extParams = getParamsFromProduct(item, eventType);
    }
    const res = { ...newReportJson, ...extParams };
    console.log('getReportJson', res);
    return res;
  };
  