import {loadScript} from "../utils/tool";
import {stringify} from 'qs'
import {_request} from "./require";
//是否调用预发环境，默认false-调用线上
let DEV = process.env.NODE_ENV === "pro";

import {initSign} from "../utils/SignInit";
import sha256 from '../utils/sha256.js'
// https://cf.jd.com/pages/viewpage.action?pageId=966814604

/**
 * 调用时控制预发线上环境
 * @param env 字符串为dev入参时使用预发接口
 */
export const setEnv = (env) => {
    if(env === 'dev') {
        //调用预发
        DEV = true
    } else {
        //不调用预发，调用线上
        DEV = false
    }
}
const as=async (params)=>{
    const data = {
        functionId:'jutouDisplayIndex',
        appid: 'pages-factory',
        body: JSON.stringify(params),
    }
    try{
        // mid-request不一定已经执行完成，获取不到midRequestUG
        if(!window.midRequestUG){
            await loadScript('https://storage.360buyimg.com/cdn-common/mid-request/prod/index.js?v=1')
        }
        const {mergeSecurityParams} = await window.midRequestUG
        const res = await mergeSecurityParams(data)
        return res
    }catch(err){
        return {}
    }
}
/**
 * 获取弹窗数据接口
 * @param params {taskEncExtKey:"任务加密key,任务列表获取：encExtKey 字段 必填"}
 * @returns {Promise<unknown>}
 */
export const rationPage =  async (params) => {
    const res=await as(params)
    const [
        param, //调用网关所需参数
        headers,//请求头
    ] = res || {}
    console.log(res, "234")
    return _request({
            url: DEV ? "//beta-api.m.jd.com" : "//api.m.jd.com",
            data: {
                appid: param.appid,
                functionId: param.functionId,
                body: param.body,
                ...param
            },
            headers: { "Content-Type": "application/x-www-form-urlencoded",...headers },
        })
}
