//请求方法
interface opts {
    url?: string,
    type?:string,
    data?: object,
    dataType?: string, // 领取渠道2
    jsonp?: string, // 自定义class样式
    taskTime?: number, // 自定义倒计时时间
    extInfo?: object // 扩展字段
}
const baseUrl:string =
  process.env.NODE_ENV == "pro" ? "//api.m.jd.com" : "//beta-api.m.jd.com";

export const _request=async ({url=baseUrl,type,data,dataType,jsonp,headers}:opts)=>{
    return new Promise((resolve,reject)=>{
        const params = {
            url: url,
            type: type||"POST",
            data: data,
            headers:{...headers},
            xhrFields: {
                withCredentials: true
            },
            dataType: dataType || "json",

            jsonp: jsonp || "",
            timeout: 3000,
            success: (res) => {
                resolve(res)
            },
            error: (error) => {
                console.log(error)
                reject(error)
            },
        }
        $.ajax(params);
    })
}