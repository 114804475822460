// 倒计时:时分秒
export function countTimeFn(nowDate, lastDate,callback){
    if(!nowDate&&!lastDate){
        return false
    }
    let timeId =null;
    let formatT=nowDate.replace(/-/g, "/")
    let nowTime=new Date(formatT).getTime()
    function countTime(leftTime) {
        //定义变量,h,m,s保存倒计时的时间
        let h, m, s;
        console.log(leftTime, "ddd")
        if (leftTime >= 0) {
            h = Math.floor(leftTime / 1000 / 60 / 60);
            m = Math.floor(leftTime / 1000 / 60 % 60);
            s = Math.floor(leftTime / 1000 % 60);
            if(h < 10){
                h=`0${h}`
            }
            if(m < 10){
                m=`0${m}`
            }
            if(s < 10){
                s=`0${s}`
            }
        } else {
            clearInterval(timeId)
            callback&&callback("over")
        }
        callback&&callback(h,m,s)
    }
    //注意时间会有误差所以是每1050毫秒掉一次
    timeId = setInterval(()=>{
        nowTime+=1000;
        let end=lastDate.replace(/-/g,"/")
        //时间差
        let leftTime = new Date(end).getTime()-nowTime;
        if(leftTime<=0){
            clearInterval(timeId)
            callback&&callback("over")
            return false
        }
        countTime(leftTime)
    },1050)
}
// 倒计时-S
export function countS(time,callback){
    if(!time){
        return false
    }
    let timeId =null;
    //注意时间会有误差所以是每1050毫秒掉一次
    timeId = setInterval(()=>{
        console.log(timeId, time, "倒计时是否还在")
        //时间差
        if(time<0){
            clearInterval(timeId)
            callback&&callback()
            return false
        }
        callback&&callback(time,timeId)
        time-=1
    },1050)
}


/**
 *
 * @param {Object} script  {src, innerText}
 * @param {Boolean} rm  加载完成后移除dom
 */
export function loadScript(script, rm = true) {
    if (!script) {
        return Promise.resolve()
    }
    if (typeof script === "string") {
        script = { src: script }
    }
    const { src, innerText, attributes = {} } = script
    return new Promise((resolve, reject) => {
        const s = document.createElement("script")
        script.type = "script"
        if (src) {
            s.async = true
            s.onload = () => resolve(s)
            s.onerror = (e) => {
                reject(e)
            }
            s.src = src
        } else {
            s.textContent = innerText
        }
        // insert
        const head = document.getElementsByTagName("head")[0]
        try {
            head.appendChild(s)
        } catch (err) {}
        if (!src) {
            resolve(s)
        }
    }).then((s) => {
        // clean-up
        rm && s.parentNode.removeChild(s)
    })
}