/**
 * 挽留弹框方法
 */
import { getUrlQuery, isApp } from "@jd/fe-common"

export function retainClose() {
	if (isApp("wx")) {
		if (isApp("mp")) {
			window.wx && window.wx.miniProgram && window.wx.miniProgram.navigateBack && window.wx.miniProgram.navigateBack()
		} else {
			setTimeout(() => {
				const closeWxWindow = () => {
					window.WeixinJSBridge && window.WeixinJSBridge.invoke("closeWindow")
				}
				window.WeixinJSBridge
					? closeWxWindow()
					: document.addEventListener("WeixinJSBridgeReady", () => {
						closeWxWindow()
					})
			}, 300)
		}
	} else if (isApp("jdlittle")) {
		setTimeout(() => {
			window.JSKIT && window.JSKIT.navigation && window.JSKIT.navigation.closeWebview()
		}, 300)
	} else if (isApp("jd")) {
		setTimeout(() => {
			window.JSSDK &&
			window.JSSDK.WebView &&
			window.JSSDK.WebView.closeActiveWebview &&
			window.JSSDK.WebView.closeActiveWebview()
		}, 300)
	} else {
		setTimeout(() => {
			window.close()
		}, 300)
	}
	console.log(window.history.length, "dddd")
	if (window.history.length <= 2) {
		window.close()
	}
	window.history.go(-1)
}
export function retainFn(callback) {
	const url = window.location.href
	if (/retain=true/.test(url)) return
	// todo：此处currUrl的拼接方式，可能会影响Home的挽留效果，未复现问题，待定。
	let currUrl =
		window.location.origin +
		(window.location.pathname ? window.location.pathname : "") +
		(window.location.hash ? window.location.hash : "")
	// 形如: local.jd.com:3031/#/retain?b=ccc
	// hash = "#/retain?b=ccc"    search=""
	// 形如: local.jd.com:3031/?b=ccc#/retain
	// hash = "#/retain"    search="?b=ccc"
	// 拼接search
	if (currUrl.includes("?")) {
		// 此处链接有问号，所以去掉search的问号
		currUrl += window.location.search.substring(1)
	} else {
		// 此处直接拼接search，
		currUrl += window.location.search
	}
	if (currUrl.includes("?")) {
		window.history.replaceState(null, null, `${currUrl}&retain=true`)
	} else {
		window.history.replaceState(null, null, `${currUrl}?retain=true`)
	}
	console.log(currUrl, "dsdfdd")
	window.history.pushState(null, null, currUrl)
	window.addEventListener(
		"popstate",
		function () {
			const isPopup = getUrlQuery("retain")
			console.log(window.location.href, "href")
			if (isPopup) {
				callback && callback()
			}
		},
		false
	)
}
