/**
 * @time: 2022/12/7
 * @user: yangkai9
 */
/**
 * webview 导航栏返回按钮-挽留
 * 参考文档：http://doc.jd.com/webview/doc/h5/m_page/navigation/back.html
 * 2022/11/1
 * author: lijiamiao
 * email: lijiamiao3@jd.com
 * 【写在前面】
 * webview导航栏后退按钮-挽留使用方法
 * 满足要使用挽留功能时，调用该方法
 * if(需要挽留功能){
 *   const options = {
 *     success:()->{},后退拦截成功，的回调,在此处做弹窗展示
 *     fail:()->{},后退拦截失败，的回调，可不传，默认返回上一页
 *   }
 *   webviewRetainFn(options)
 * }
 * 例如商品弹窗：
 * 需要在首次点击返回且弹窗有数据时，需要展示挽留弹窗
 * if (onOff && (materialData?.length === 4 || adImgUrl)) {
 * 			const options = {
 *         success:()->{
 *           // 此处控制展示商品弹窗
 *         }, //后退拦截成功，的回调,在此处做弹窗展示
 *         fail:()->{}, //后退拦截失败，的回调，可不传，默认返回上一页
 *      }
 *      webviewRetainFn(options)
 * }
 */

/**
 * todo: 后面可结合popstate挽留，优化一下
 */

import { isAndroid, isApp, isIOS } from "@jd/fe-common"

/**
 * 返回上一页
 */
const goBackLastPage = () => {
  // 京东APP中的返回上一页
  if (isApp("jd")) {
    if (isAndroid()) {
      try {
        window.JDAppUnite && window.JDAppUnite.notifyMessageToNative(JSON.stringify({ businessType: "goBack" }))
      } catch (e) {
        //	 todo:异常上报
      }
    } else if (isIOS()) {
      try {
        // iOS
        const iosParams = {
          routerURL: "router://JDWebViewBusinessModule/enableWebControlBack", // required,必选参数
          routerParam: { businessType: "goBack" }, // 需要传入的参数，这里的key自定义的，optional,可选参数
          callBackName: "", // h5自定义的函数名称，optional,可选参数
          callBackId: "retainNew", // h5自定义的参数，用于区分同一个页面，optional,可选参数
        }

        window.webkit.messageHandlers?.JDAppUnite?.postMessage({
          method: "callRouterModuleWithParams",
          params: iosParams,
        })
      } catch (e) {
        //	 todo:异常上报
      }
    }
  }
}

/**
 * 导航栏后退挽留
 * options
 * success - void - 后退拦截成功，的回调
 * fail - void - 后退拦截失败，的回调
 * controlType:"1"-注销，"2"-注册  注册或者取消监听方法-默认注册
 */
const webviewRetainFn = (options) => {

  if (!isApp("jd")) return
  // webview申请拦截挽留弹窗
  console.log(isAndroid(), isIOS(), "webviewRetainFn")
  webControlBack("2")
  // 挽留成功及失败的回调
  const { success, fail } = options
  try {
    // 申请web view的返回拦截，
    window.gobackSDK_Callback = function (res) {
      res = JSON.parse(res)
      console.log(res, "gobackSDK_Callback")
      // Android回调：{"status":"0","data":"","message":""} //status 0为成功，1为失败
      // iOS回调：{"status":"0","data":"0","message":""} //status和data同为0时成功
      if (res.status === "0" && (isAndroid() || (isIOS() && res.data === "0"))) {
        // 申请拦截成功,会直接调用window.jdWebviewClickBackCallBack方法
        console.log("申请拦截成功啦====")
      } else {
        console.log("申请拦截失败====")
        if (typeof fail === "function") {
          fail()
          return
        }
        // 兜底，若没有传失败处理方法，则默认返回上一页
        goBackLastPage()
      }
    }
    if (typeof success === "function") {
      // 导航栏后退按钮点击事件,拦截成功的回调 将挽留方法挂在在全局
      console.log("注销触发")
      window.jdWebviewClickBackCallBack = ()=> {
        console.log("注销触发1")
        webControlBack("1")
        success()
      }
      return
    }
    // 赋值默认函数
    window.jdWebviewClickBackCallBack = function () {}
  } catch (e) {
    //	todo:异常上报
  }
}
/**
 * 注册或者取消监听方法
 * @param controlType:"1"-注销，"2"-注册
 */
const webControlBack = (controlType) => {
    console.log(controlType, "cc")
    // webview申请拦截挽留弹窗
    // Android
    if (isAndroid()) {
      try {
        let callback = JSON.stringify({
          callBackName: "window.gobackSDK_Callback",
          controlType: controlType,
        })
        window.MobileNavi && window.MobileNavi?.enableWebControlBack(callback) // callback为可选项，可为空串
        // Android回调：{"status":"0","data":"","message":""} //status 0为成功，1为失败
      } catch (e) {
        //	 todo:异常上报
      }
    } else if (isIOS()) {
      try {
        // iOS
        const params = {
          routerURL: "router://JDWebViewBusinessModule/enableWebControlBack", // required,必选参数
          routerParam: {
            businessType: "enableWebControlBack",
            controlType: controlType,
          }, // 需要传入的参数，这里的key自定义的，optional,可选参数
          callBackName: "window.gobackSDK_Callback", // h5自定义的函数名称，optional,可选参数
          callBackId: "retainNew", // h5自定义的参数，用于区分同一个页面，optional,可选参数
        }
        window.webkit?.messageHandlers?.JDAppUnite?.postMessage({
          method: "callRouterModuleWithParams",
          params: params,
        })
      } catch (e) {
        //	 todo:异常上报
      }
    }
}
export { goBackLastPage, webviewRetainFn }
